






















































































































import { Component, Vue, Prop } from 'vue-property-decorator';

import Loading from '@/components/Loading/Loading.vue';

import iArrow from '@/assets/icons/Arrow.vue';

import { NavigationKeys } from '@/mixins/NavigationKeys';

import { setNumber } from '@/share/Util/UsefulFunctions/UserfulFunctions';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

const FIRST_INDEX = 1;

const DEFAULT_COLOR_ICON = 'var(--primarycolor-pure)';
const DEFAULT_COLOR_ICON_DISABLED = 'var(--primarycolor-lightest)';

@Component({
  components: {
    iArrow,
    Loading,
  },
  mixins: [NavigationKeys],
  filters: {
    setNumber,
  },
})
export default class ContainerTutorial extends Vue {
  @Prop() header!: string;
  @Prop() title!: string;
  @Prop() endButtonName!: string;
  @Prop() pageCurrent!: number;
  @Prop() totalPage!: number;
  @Prop({ default: false }) showFooter!: boolean;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: true }) showButtonSkip!: boolean;
  @Prop({ default: true }) showRepeatTutorialButton!: boolean;

  private DEFAULT_COLOR_ICON = DEFAULT_COLOR_ICON;
  private DEFAULT_COLOR_ICON_DISABLED = DEFAULT_COLOR_ICON_DISABLED;

  get isFirstPage() {
    return this.pageCurrent === FIRST_INDEX;
  }

  get isLastPage() {
    return this.pageCurrent === this.totalPage;
  }

  skipTutorial() {
    this.setTrackAmplitude();

    this.$emit('skip-tutorial');
  }

  handleEndTutorialButton() {
    this.$emit('end-tutorial-button');
  }

  clickButtonShowRepeatTutorial() {
    this.$emit('repeat-tutorial');
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'ContainerTutorial',
          local: this.$route.name,
        },
      },
    });
  }
}
