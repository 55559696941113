import { Component, Vue } from 'vue-property-decorator';

@Component({})
export class NavigationKeys extends Vue {
  created() {
    this.onKeyDown();
  }

  beforeDestroy() {
    document.removeEventListener('keydown', this.checkKey);
  }

  onKeyDown() {
    document.addEventListener('keydown', this.checkKey);
  }

  checkKey(event: any) {
    const { key } = event;

    switch (key) {
      case 'ArrowLeft':
        this.previousPage();
        break;
      case 'ArrowRight':
        this.nextPage();
        break;
      default:
        break;
    }
  }

  previousPage() {
    this.$emit('previousPage');
  }

  nextPage() {
    this.$emit('nextPage');
  }
}
