export const DATA_TUTORIAL_PLANNER = [
  {
    id: 0,
    title: 'Olá, tudo bem com você?',
    text: '<p>Antes de começar, gostaríamos de explicar como funciona o nosso planner.</p>',
  },
  {
    id: 1,
    title: 'Criando seu planner',
    text: '<p>Para criarmos o seu planner personalizado, você precisará nos dar informações sobre: <strong>o seu período de estudos, os seus horários disponíveis, qual tipo de prova e quais assuntos que você deseja focar.</strong> A partir disso, montaremos um planner que irá se adaptar à sua rotina.</p>',
    imgs: [
      {
        src: 'config-planner.png',
        alt: 'A imagem mostra a página de configuração do planner',
      },
    ],
  },
  {
    id: 2,
    title: 'Validando seu planner',
    text: '<p>Após você adicionar todas as informações, iremos validar se vai ser possível gerar seu planner conforme a <strong>quantidade de assuntos e tempo definidos.</strong></p><br><p>Caso não, você terá que <strong>escolher entre adicionar mais horários ao seu planner ou remover assuntos a serem estudados.</strong></p>',
  },
  {
    id: 3,
    title: 'Visualizando seu planner',
    text: '<p>Para a visualização do seu planner será disponibilizado <strong>todos os conteúdos referente à “semana atual”, que sempre se inicia aos domingos.</strong> As demais semanas serão liberadas para o acesso de forma gradual.</p><br><p><strong>Quando uma semana acabar, ela será arquivada e você não terá mais o seu acesso.</strong> Mas não se preocupe, pois <strong>todo o conteúdo que você não acessou na semana será transferido para a semana seguinte.</strong></p>',
    imgs: [
      {
        src: 'planner.png',
        alt: 'A imagem mostra a página do planner',
      },
    ],
  },
  {
    id: 4,
    title: 'Resumo do seu planner',
    text: '<p>Você também poderá visualizar o <strong>resumo da configuração do seu planner.</strong></p><br><p>E, além disso, você pode optar por <strong>refazer o seu planner a qualquer momento.</strong></p>',
    imgs: [
      {
        src: 'resume-planner.png',
        alt: 'A imagem mostra a seção de resumo do planner',
      },
    ],
  },
  {
    id: 5,
    title: 'Conheceu nosso planner?',
    text: '<p>Agora que já sabe tudo o que precisa saber sobre o nosso planner. Vamos criar o seu?</p>',
  },
];
