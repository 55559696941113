import { Component, Vue } from 'vue-property-decorator';

import { DataTutorial } from '@/globalInterfaces/Tutorial';

const FIRST_INDEX_TUTORIAL = 0;
const NAVIGATION_NUMBER = 1;
const PAGE_NUMBER = 1;

@Component({})
export class NavigationTutorials extends Vue {
  private currentTutorial: DataTutorial | Record<string, any> = {};
  private dataTutorials: Array<DataTutorial> | [] = [];
  private totalPage = 0;

  get lastPage() {
    return this.totalPage - PAGE_NUMBER;
  }

  get firstTutorial() {
    return this.dataTutorials[FIRST_INDEX_TUTORIAL];
  }

  get lastTutorial() {
    return this.dataTutorials[this.lastPage];
  }

  saveDataTutorials(listTutorials: Array<DataTutorial>) {
    this.dataTutorials = listTutorials;
    this.currentTutorial = listTutorials[FIRST_INDEX_TUTORIAL];
    this.totalPage = listTutorials.length;
  }

  setPreviousTutorial() {
    this.currentTutorial = this.currentTutorial === this.firstTutorial ? this.currentTutorial : this.previousTutorial();
  }

  setNextTutorial() {
    this.currentTutorial = this.currentTutorial === this.lastTutorial ? this.currentTutorial : this.nextTutorial();
  }

  nextTutorial() {
    if (this.currentTutorial) {
      return this.dataTutorials[this.currentTutorial.id + NAVIGATION_NUMBER];
    }
    return {};
  }

  previousTutorial() {
    if (this.currentTutorial) {
      return this.dataTutorials[this.currentTutorial.id - NAVIGATION_NUMBER];
    }
    return {};
  }

  repeatTutorial() {
    this.currentTutorial = this.firstTutorial;
  }

  setSkipTutorial() {
    this.currentTutorial = this.lastTutorial;
  }
}
